<template>
  <div class="page-box" ref="page">
    <a-spin tip="Loading..." :spinning="formLoading">
      <a-form-model :loading="formLoading" ref="ruleForm" :model="form" :rules="disabled?{}:Object.assign(rules,customerRules)" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-model-item label="活动名称：" prop="activityName">
          <a-input v-model="form.activityName" v-if="!disabled" style="width: 300px" allowClear placeholder="活动名称" />
          <div v-else>{{form.activityName}}</div>
        </a-form-model-item>
        <a-form-model-item label="开始时间：" prop="beginTime">
          <div v-if="!disabled">
            <a-date-picker value-format="YYYY-MM-DD HH:mm:ss" v-model="form.beginTime" :disabled-date="disabledStartDate" show-time format="YYYY-MM-DD HH:mm:ss" placeholder="开始时间" @change="ruleForm.validateField('beginTime')" />
          </div>
          <div v-else>{{moment(form.beginTime||new Date()).format('YYYY-MM-DD HH:mm:ss')}}</div>
        </a-form-model-item>
        <a-form-model-item label="结束时间：" prop="endTime">
          <div v-if="!disabled">
            <a-date-picker value-format="YYYY-MM-DD HH:mm:ss" @change="ruleForm.validateField('endTime')" v-model="form.endTime" :disabled-date="disabledEndDate" show-time format="YYYY-MM-DD HH:mm:ss" placeholder="结束时间" :disabled-time="disabledEndDateTime" :disabled="!form.beginTime" />
          </div>
          <div v-else>{{moment(form.endTime||new Date()).format('YYYY-MM-DD HH:mm:ss')}}</div>
        </a-form-model-item>
        <a-form-model-item label="活动商品：" prop="productList">
          <a-button v-if="!activityId" type="link" @click="selectProduct" :disabled="!!activityId">选择商品</a-button>
          <a-table bordered :pagination="false" v-if="form.productList.length" :columns="disabled?[columns1[0]]:columns1" :rowKey="record => record.productId" :dataSource="form.productList">
            <template slot="name" slot-scope="text,record">
              <div class="good-name-box">
                <base-img :src="record.productImg" style="flex-shrink:0;margin-right:8px;" width="60" height="60"></base-img>
                <div class="good-name">
                  <div class="name">{{record.name}}</div>
                  <div>¥{{record.price}}</div>
                </div>
              </div>
            </template>
            <template slot="operation">
              <a-button type="link" style="padding:0" :disabled="!!activityId" @click="deletSelectProduct">删除</a-button>
            </template>
          </a-table>
        </a-form-model-item>
        <a-form-model-item label="秒杀设置：" :required="!disabled" v-if="form.productList.length&&form.productList[0].skuList.length">
          <a-table bordered :pagination="false" :columns="form.productList[0].skuList[0].skuName ?columns2:columns3" :rowKey="(record,index) => index" :dataSource="form.productList[0].skuList">
            <template slot-scope="text,record" slot="skuName">
              {{record.skuName||form.productList[0].name}}
            </template>
            <template slot-scope="text,record" slot="supplyPrice">
              {{record.supplyPrice||'-'}}
            </template>
            <template slot-scope="text,record" slot="skuPic">
              <base-img class="goods-img" :src="record.skuPic||form.productList[0].productImg" width="60" height="60" oss_style="60_60" />
            </template>
            <template slot-scope="text,record,index" slot="seckillPrice">
              <a-form-model-item label :prop="'seckillPrice'+index">
                <div v-if="!disabled">
                  <a-input-number :precision="2" :min="0" :max='record.price' @change="changeSeckillPrice($event,record,index)" v-model="record.seckillPrice" placeholder="请输入" style="width:120px"></a-input-number>
                </div>
                <div v-else>{{record.seckillPrice}}</div>
              </a-form-model-item>
            </template>
            <template slot-scope="text,record,index" slot="seckillStock">
              <a-form-model-item label :prop="'seckillStock'+index">
                <div v-if="!disabled">
                  <a-input-number :min="0" :max='record.stock' @change="changeSeckillStock($event,record,index)" v-model="record.seckillStock" placeholder="请输入" style="width:120px"></a-input-number>
                </div>
                <div v-else>{{record.seckillStock}}</div>
              </a-form-model-item>
            </template>
          </a-table>
          <div class="batch-box" v-if="!disabled">
            <p>批量设置：</p>
            <a-button type="link" @click="batchStockShow = false;batchPriceShow = true">秒杀价</a-button>
            <a-button type="link" @click="batchPriceShow = false;batchStockShow = true">秒杀库存</a-button>
            <div v-if="batchPriceShow||batchStockShow">
              <a-input v-if="batchPriceShow" v-model="batch.seckillPrice" placeholder="请输入秒杀价" style="width:200px" allowClear></a-input>
              <a-input v-if="batchStockShow" v-model="batch.seckillStock" placeholder="请输入秒杀库存" style="width:200px" allowClear></a-input>
              <a-button type="primary" size="small" style="margin:0 12px" @click="changeBatchSeckillPrice">确定</a-button>
              <a-button type="default" size="small" @click="batch={};batchPriceShow = false;batchStockShow=false">取消</a-button>
            </div>
          </div>
        </a-form-model-item>

        <a-form-model-item label="每人限购" ref='payLimit' prop="payLimit" :autoLink="false" :extra="form.productList.length&&form.productList[0].skuList.length?'':'请先选择商品'">
          <template v-if="!disabled">
            <a-checkbox v-model="form.payLimitFlag" :disabled="!form.productList||!form.productList.length" @change="form.payLimit = arguments[0].target.checked ? 1 : 0">
              开启限购
            </a-checkbox>
            <div v-if="form.payLimitFlag">
              每人限购
              <a-input-number v-model="form.payLimit" :min="1" :max='maxSeckillStock' :precision="0"></a-input-number> 件商品
            </div>
          </template>
          <span v-else>{{form.payLimitFlag ? `每人限购${form.payLimit}件商品` : '不限购'}}</span>
        </a-form-model-item>
        <a-form-model-item label="订单取消：" prop="orderCancel">
          <div v-if="!disabled" class='flex-a-c'>
            <div>买家</div>
            <a-input-number :min="5" :max="60" :precision="0" v-model="form.orderCancel" style="width: 100px;margin:0 4px;" allowClear placeholder="请输入" />
            <div>分钟未支付订单，订单取消</div>
          </div>
          <div v-else>买家 {{form.orderCancel}} 分钟未支付订单，订单取消</div>
        </a-form-model-item>
        <a-row type="flex" align="middle" justify="center" v-if="!disabled">
          <a-button  type="primary" @click="onSubmit" :loading="saveBtnLoading">保存</a-button>
          <a-button style="margin-left: 16px;"  @click="closePage">取消</a-button>
        </a-row>
      </a-form-model>
    </a-spin>
    <a-modal title="选择商品" :visible="modalShow" width="800px" @cancel="modalCancel" @ok="modalConfirm" :getContainer="()=>$refs.page">
      <div class="search-box">
        <a-input placeholder="搜索商品名称" style="width: 200px;" v-model="productName"></a-input>
        <a-button type="primary" style="margin:0 16px;" @click="selectProduct">搜索</a-button>
        <a-button @click="resetSearch">重置</a-button>
      </div>
      <a-table bordered :columns="columns" :dataSource="selectProductList" :pagination="pagination" :rowKey="record => record.productId" :loading="modalLoading" :row-selection="{ type:'radio',selectedRowKeys: selectedRowKeys, onChange: onSelectChange, getCheckboxProps: getCheckboxProps  }" @change="handleTableChange">
        <template slot="name" slot-scope="text,record">
          <div class="good-name-box">
            <base-img :src="record.productImg" style="flex-shrink:0;margin-right:8px;" width="60" height="60"></base-img>
            <div class="good-name">
              <a-button class="name" type="link" @click="goGoodDetail(record.productId)">{{record.name}}</a-button>
              <div style="color:#ED6A0C;">¥{{record.price}}</div>
            </div>
          </div>
        </template>
      </a-table>
    </a-modal>
  </div>
</template>
<script>
import { reactive, toRefs, onMounted } from '@vue/composition-api'
import moment from 'moment'
import { activity } from '@/api'
import { activityTypes } from '@/utils/constants'
export default {
  name: 'PageGroupActivityAdd',
  setup (props, { root }) {
    // modal
    const columns = [
      {
        title: '商品名称',
        dataIndex: 'name',
        width: 300,
        scopedSlots: { customRender: 'name' },
      },
      {
        title: '库存',
        dataIndex: 'stock',
      },
      {
        title: '销量',
        dataIndex: 'sales',
      },
      {
        title: '不可选原因',
        dataIndex: 'activityType',
        customRender: (x, record) => {
          if (x) {
            return (
              '该商品正在参加【' + activityTypes.find((item) => item.value === x).name + '】活动'
            )
          } else {
            return '-'
          }
        },
      },
    ]
    // 商品
    const columns1 = [
      {
        title: '商品名称',
        dataIndex: 'name',
        scopedSlots: { customRender: 'name' },
      },
      {
        title: '操作',
        dataIndex: 'operation',
        scopedSlots: { customRender: 'operation' },
      },
    ]
    // 商品规格
    const columns2 = [
      {
        title: '商品图',
        dataIndex: 'skuPic',
        scopedSlots: { customRender: 'skuPic' },
      },
      {
        title: '规格名',
        dataIndex: 'skuName',
        scopedSlots: { customRender: 'skuName' },
      },
      {
        title: '零售价（元）',
        dataIndex: 'price',
      },
      {
        title: '成本价（元）',
        dataIndex: 'supplyPrice',
        scopedSlots: { customRender: 'supplyPrice' },
      },
      {
        title: '秒杀价（元）',
        dataIndex: 'seckillPrice',
        scopedSlots: { customRender: 'seckillPrice' },
      },
      {
        title: '库存',
        dataIndex: 'stock',
      },
      {
        title: '秒杀库存',
        dataIndex: 'seckillStock',
        scopedSlots: { customRender: 'seckillStock' },
      },
    ]
    const columns3 = [
      {
        title: '商品图',
        dataIndex: 'skuPic',
        scopedSlots: { customRender: 'skuPic' },
      },
      {
        title: '零售价（元）',
        dataIndex: 'price',
      },
      {
        title: '成本价（元）',
        dataIndex: 'supplyPrice',
        scopedSlots: { customRender: 'supplyPrice' },
      },
      {
        title: '秒杀价（元）',
        dataIndex: 'seckillPrice',
        scopedSlots: { customRender: 'seckillPrice' },
      },
      {
        title: '库存',
        dataIndex: 'stock',
      },
      {
        title: '秒杀库存',
        dataIndex: 'seckillStock',
        scopedSlots: { customRender: 'seckillStock' },
      },
    ]
    const labelCol = { span: 2 }
    const wrapperCol = { span: 18 }
    onMounted(() => {
      if (root.$route.path.indexOf('/activity/seckill/detail') !== -1) {
        state.disabled = true
      } else {
        state.disabled = false
      }
      state.activityId = root.$route.params.id || ''
      if (state.activityId) {
        getData()
      }
    })
    const state = reactive({
      activityId: '',
      formLoading: false,
      modalShow: false,
      modalLoading: false,
      batchPriceShow: false,
      batchStockShow: false,
      saveBtnLoading: false,
      form: {
        activityName: '',
        productList: [],
        beginTime: null,
        endTime: null,
        payLimit: 0,
        payLimitFlag: false,
        orderCancel: 5
      },
      batch: {},
      selectProductList: [],
      formBatch: null,
      ruleForm: null,
      productName: '',
      maxSeckillStock: 0,
      selectedRowKeys: [],
      selectedRows: [],
      pagination: {
        current: 1,
        pageSize: 5,
        total: 0,
        size: 'small',
      },
      disabled: false,
      customerRules: {},
      rules: {
        activityName: [
          { required: true, message: '请输入活动名称', trigger: 'change' },
          { max: 32, message: '请尝试简短一些的活动名称', trigger: 'change' },
        ],
        beginTime: [{ required: true, message: '请选择活动开始时间', trigger: 'change' }],
        endTime: [{ required: true, message: '请选择活动结束时间', trigger: 'change' }],
        productList: [{ required: true, message: '请选择活动商品', trigger: 'change' }],
        seckillPrice: [{ required: true, message: '请输入秒杀价', trigger: 'change' }],
        seckillStock: [{ required: true, message: '请输入秒杀库存', trigger: 'change' }],
        orderCancel: [{ required: true, message: '请输入订单取消时间', trigger: 'change' }]
      },
    })
    function onSubmit () {
      state.ruleForm.validate(async (valid) => {
        if (valid) {
          console.log(state.form)
          let params = deepClone(state.form)
          params.productList = params.productList[0].skuList.map(x => {
            return {
              ...x,
              productMainPic: params.productList[0].productImg,
              skuPic: x.skuPic || params.productList[0].productImg,
              productId: params.productList[0].productId,
              productName: params.productList[0].name,
              productSkuName: x.skuName,
            }
          })
          state.saveBtnLoading = true
          const { msg, code } = await activity.seckillActivitySave(params)
          state.saveBtnLoading = false
          if (code === '00000') {
            root.$bus.$emit('PageSeckillList:refresh')
            root.$message.success('保存成功')
            closePage()
          } else {
            root.$message.error(msg || '保存失败')
          }
        }
      })
    }
    function closePage () {
      if (root.$store.state.tabs.views.map((item) => item.path).indexOf('/activity/seckill') !== -1) {
        root.$closeCurrentView()
      } else {
        root.$closeCurrentView('/activity/seckill')
      }
    }
    async function selectProduct () {
      state.modalShow = true
      state.modalLoading = true
      let { code, data, page, msg } = await activity.getActivitySelectProductpage({
        current: state.productName !== '' ? 1 : state.pagination.current,
        size: state.pagination.pageSize,
        name: state.productName,
      })
      state.modalLoading = false
      if (code === '00000') {
        state.selectProductList = data.map((item) => {
          if (item.skuList.length === 0) {
            item.skuList.push({
              price: item.price,
              stock: item.stock,
              productId: item.productId,
            })
          }
          return item
        })
        state.pagination.current = page.pageNo
        state.pagination.pageSize = page.pageSize
        state.pagination.total = page.total
      } else {
        root.$message.error(msg || '获取数据失败')
      }
    }
    function handleTableChange ({ current }) {
      state.pagination.current = current
      selectProduct()
    }
    function onSelectChange (selectedRowKeys, selectedRows) {
      state.selectedRowKeys = selectedRowKeys
      state.selectedRows = selectedRows
    }
    function getCheckboxProps (record) {
      return {
        props: { disabled: !!record.activityType },
      }
    }
    function modalCancel () {
      state.selectedRowKeys = []
      state.selectedRows = []
      state.modalShow = false
    }
    function modalConfirm () {
      state.selectedRows[0].skuList.forEach((item) => {
        item.seckillPrice = ''
        item.seckillStock = ''
      })
      root.$set(state.form, 'productList', deepClone(state.selectedRows))
      state.customerRules = {}
      state.form.productList[0].skuList.forEach((x, index) => {
        state.customerRules['seckillPrice' + index] = [
          { required: true, message: '请输入秒杀价', trigger: 'change' },
        ]
        state.customerRules['seckillStock' + index] = [
          { required: true, message: '请输入秒杀库存', trigger: 'change' },
        ]
        root.$set(state.form, 'seckillPrice' + index, '')
        root.$set(state.form, 'seckillStock' + index, '')
      })
      state.ruleForm.validateField('productList')
      state.modalShow = false
      console.log(state.customerRules)
    }
    function deepClone (obj) {
      let objClone = Array.isArray(obj) ? [] : {}
      if (obj && typeof obj === 'object') {
        for (let key in obj) {
          if (obj.hasOwnProperty(key)) {
            // 判断obj子元素是否为对象，如果是，递归复制
            if (obj[key] && typeof obj[key] === 'object') {
              objClone[key] = deepClone(obj[key])
            } else {
              // 如果不是，简单复制
              objClone[key] = obj[key]
            }
          }
        }
      }
      return objClone
    }
    function goGoodDetail (productId) {
      root.$router.push(`/goods/goods_detail/${productId}`)
    }
    function deletSelectProduct () {
      state.selectedRowKeys = []
      state.selectedRows = []
      state.form.productList = []
      state.customerRules = {}
    }
    function changeBatchSeckillPrice (e) {
      root.$set(
        state.form.productList[0],
        'skuList',
        state.form.productList[0].skuList.map((item, index) => {
          if (state.batchPriceShow) {
            item.seckillPrice = Number(state.batch.seckillPrice)
            root.$set(state.form, 'seckillPrice' + index, Number(state.batch.seckillPrice))
          } else if (state.batchStockShow) {
            item.seckillStock = Number(state.batch.seckillStock)
            root.$set(state.form, 'seckillStock' + index, Number(state.batch.seckillStock))
          }
          return item
        })
      )
      getMaxSeckillStock()
      for (let key in state.customerRules) {
        if (state.batchPriceShow && key.indexOf('seckillPrice') !== -1) {
          state.ruleForm.validateField(key)
        }
        if (state.batchStockShow && key.indexOf('seckillStock') !== -1) {
          state.ruleForm.validateField(key)
        }
      }
      state.batchPriceShow = false
      state.batchStockShow = false
    }
    function resetSearch () {
      state.productName = ''
      selectProduct()
    }
    async function getData () {
      let { code, data, msg } = await activity.getSeckillActivityDetail(state.activityId)
      if (code === '00000') {
        console.log(data)
        // beginTime: moment(data.beginTime),
        //   endTime: moment(data.endTime)
        let productList = []
        productList = [{
          productImg: data.productList[0].productMainPic,
          productId: data.productList[0].productId,
          name: data.productList[0].productName,
          price: Math.min.apply(Math, data.productList.map(x => x.price)),
          skuList: data.productList.map(x => {
            return {
              skuName: x.productSkuName,
              ...x
            }
          })
        }]
        data.productList = productList
        state.form = data
        getMaxSeckillStock()
      } else {
        root.$message.error(msg || '获取数据失败')
      }
    }
    function disabledStartDate (startValue) {
      const endValue = moment(state.form.endTime)
      if (!startValue || !endValue) {
        return false
      }
      return startValue.valueOf() > endValue.valueOf()
    }
    function disabledEndDate (endValue) {
      const startValue = moment(state.form.beginTime)
      const nowValue = moment(new Date())
      if (!endValue || !startValue) {
        return false
      }
      if (nowValue.valueOf() >= startValue.valueOf()) {
        return nowValue.valueOf() - 24 * 3600 * 1000 >= endValue.valueOf()
      } else {
        return startValue.valueOf() >= endValue.valueOf()
      }
    }
    function range (start, end) {
      const result = []
      for (let i = start; i < end; i++) {
        result.push(i)
      }
      return result
    }
    function disabledEndDateTime () {
      // 结束时间 比开始时间大于 1天，时分秒没有限制
      if (
        state.form.endTime &&
        new Date(state.form.endTime).getTime() - new Date(state.form.beginTime).getTime() >
          24 * 3600 * 1000
      ) {
        return {}
      } else {
        const startValue = moment(state.form.beginTime)
        const nowValue = moment(new Date())
        let dateTime = ''
        if (nowValue.valueOf() >= startValue.valueOf()) {
          dateTime = new Date()
        } else {
          dateTime = state.form.beginTime
        }
        return {
          disabledHours: () => range(0, new Date(dateTime).getHours()),
          disabledMinutes: () => {
            if (
              state.form.endTime &&
              new Date(state.form.endTime).getHours() > new Date(dateTime).getHours()
            ) {
              return []
            } else {
              return range(0, new Date(dateTime).getMinutes())
            }
          },
          disabledSeconds: () => {
            if (
              state.form.endTime &&
              new Date(state.form.endTime).getHours() > new Date(dateTime).getHours()
            ) {
              return []
            } else if (
              state.form.endTime &&
              new Date(state.form.endTime).getHours() === new Date(dateTime).getHours() &&
              new Date(state.form.endTime).getMinutes() > new Date(dateTime).getMinutes()
            ) {
              return []
            } else {
              return range(0, new Date(dateTime).getSeconds())
            }
          },
        }
      }
    }
    function changeSeckillPrice (e, record, index) {
      root.$set(state.form, 'seckillPrice' + index, record.seckillPrice)
      state.batch = {}
      state.ruleForm.validateField('seckillPrice' + index)
      console.log(state.form)
    }
    function changeSeckillStock (e, record, index) {
      root.$set(state.form, 'seckillStock' + index, record.seckillStock)
      state.batch = {}
      state.ruleForm.validateField('seckillStock' + index)
      getMaxSeckillStock()
    }
    function getMaxSeckillStock () {
      let sum = 0
      state.form.productList[0].skuList.forEach((x) => {
        if (x.seckillStock) {
          sum += x.seckillStock || 0
        }
      })
      state.maxSeckillStock = sum
      console.log(sum)
    }
    return {
      disabledStartDate,
      disabledEndDate,
      disabledEndDateTime,
      labelCol,
      wrapperCol,
      moment,
      columns,
      columns1,
      columns2,
      columns3,
      ...toRefs(state),
      getData,
      onSubmit,
      selectProduct,
      getMaxSeckillStock,
      handleTableChange,
      onSelectChange,
      getCheckboxProps,
      modalCancel,
      modalConfirm,
      goGoodDetail,
      deletSelectProduct,
      changeBatchSeckillPrice,
      resetSearch,
      closePage,
      changeSeckillPrice,
      changeSeckillStock,
    }
  },
}
</script>
<style lang="less" scoped>
::v-deep .good-name-box {
  display: flex;
  align-items: center;
  width: 300px;
  .good-name {
    min-height: 60px;
    width: 232px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .name {
      padding: 0;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-align: left;
    }
  }
}
.group-price-form {
  ::v-deep .ant-form-item {
    margin-bottom: 0;
  }
}
.batch-box {
  display: flex;
  align-items: center;
}
.search-box {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  justify-content: flex-end;
}
.flex-a-c {
  display: flex;
  align-items: center;
}
::v-deep table .ant-form-item {
  margin-bottom: 0;
}
</style>
